.shadow {
  &-1 {
    // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  
  &-2 {
    box-shadow: rgba($product-accent, 0.2) 0px 0px 12px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
  }
  
  &-3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  
  &-4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  
  &-5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
}
