// colors
$indigo: #0B456E;
$orange: #E99241;
$rose: #D46063;
$white: #F8F8F8;
$gray: #2D353B;
$light-gray: rgba($gray, 0.5);
$lighter-gray: rgba($gray, 0.2);
$black: #222222;
$success-dark: #155724;
$success-medium: #c3e6cb;
$success-light: #d4edda;
$error-dark: #d10014; // #721c24;
$error-medium: #f5c6cb;
$error-light: #f8d7da;

// typography
$base-font-size: 16px;
$heading-font: 'Fira Sans', sans-serif;
$body-font: 'Montserrat', sans-serif;

// layout
$content-width: 970px;
$base-space: 16px;
$payment-icon-width: 24px;

// theme
$product-color: #434343;
$product-accent: #E30613;
