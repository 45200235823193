.payment-method-frame {
  width: 100%;
}

.pm-credit-card {
  iframe.payment-method-frame {
    height: 386px;
    width: 100%;
    background-color: #f2f5f5;
    padding-top: 64px;
    border-radius: 4px;
    border: 1px dashed #ced9d9;
  }
}

.pm-we-chat-pay {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 64px;
  border: 1px dashed $light-gray;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8);

  .wx-link {
    flex: 1;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        margin: 16px 0;
        color: $product-accent;
        font-size: 0.8rem;
      }
    }
  }

  .or {
    width: 32px;
    height: 32px;
    text-align: center;
    color: $light-gray;
  }

  .qr-code {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      margin: 16px 0;
      font-size: 0.8rem;
      color: $gray;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
}

.pm-union-pay {
  iframe.payment-method-frame {
    width: 970px;
    height: 800px;
    padding: 12px 0;
    background: white;
    border-radius: 4px;

    @media only screen and (max-width: 768px) {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }
}

.pm-ali-pay {
  iframe.payment-method-frame {
    width: 970px;
    height: 870px;
    padding: 0;
    border-radius: 4px;

    @media only screen and (max-width: 768px) {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }
}

.pm-hpf {
  padding: 16px;
  border: 2px dashed #dddd;
  border-radius: 4px;
  margin-top: 16px;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }

  .hpf-form {
    width: 480px;
    max-width: 100%;
    margin: 0 auto;
    padding: 16px;
    border: 1px solid rgba($gray, 0.1);
    border-radius: 4px;
    box-sizing: border-box;
    background-color: white;
  }

  .hpf-form-field {
    height: 1.4rem;
    margin-bottom: 1rem;
  }

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($white,0.6);

    &.show {
      display: flex;
    }

    &.hide {
      display: none;
    }
  }
}

.pm-direct-debit {
  padding: 16px;
  border: 2px dashed #dddd;
  border-radius: 4px;
  margin-top: 16px;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }

  .direct-debit-form {
    width: 480px;
    max-width: 100%;
    margin: 0 auto;
    padding: 16px;
    border: 1px solid rgba($gray, 0.1);
    border-radius: 4px;
    box-sizing: border-box;
    background-color: white;
  }

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($white,0.6);

    &.show {
      display: flex;
    }

    &.hide {
      display: none;
    }
  }
}

.hpf-form-response {
  color: #2b3333;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 1.4;
  box-sizing: border-box;
  outline: 0;
  background: #fff;
  border-color: #ced9d9;
  padding: 0 !important;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  padding: 30px;
  padding: 30px;
}

.payer_body {
  padding: 20px 0px 10px 0px;
}

.payer_top_message {
  padding-top: 44px;
  padding-left: 110px;
  padding-bottom: 53px;
  display: table;
  margin: 0 auto;
  box-sizing: border-box;
  outline: 0;
  color: #2b3333;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 1.4;
}

.payer-ico-left {
  float: left;
  width: 100px;
  margin-top: -15px;
  box-sizing: border-box;
  outline: 0;
}

.text-mint, .text-mint a, .text-minta {
  color: #00a87b;
}

* {
  box-sizing: border-box;
  outline: 0;
}

.payer-text-left {
  float: left;
  max-width: 400px;
  box-sizing: border-box;
  outline: 0; 
  color: #2b3333;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 1.4;
}

.payer-text-left {
  float: left;
  max-width: 400px;
  color: #2b3333;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 1.4;
}

.text-dark-shaded {
  font-size: 24px;
  color: #3f5555;
}

.text-light-shaded {
  font-weight: 200;
  font-style: normal;
  line-height: 1.4;
  font-size: 16px;
  color: #869898;
}

.text-regular-link {
  color: #2688DE;
  font-weight: 400;
  font-style: normal;
}

.hpf-form-error {
  height: 2.5rem;
  font-size: small;
  color: red;
}
