@import url("https://fonts.googleapis.com/css2?family=Fira+Sans&family=Montserrat:wght@200;400;600&display=swap");

@import "./styles/reset";
@import "./styles/variable";

@import "./styles/typography";
@import "./styles/layout";
@import "./styles/alert";
@import "./styles/shadow";
@import "./styles/button";
@import "./styles/input";
@import "./styles/drawer";
@import "./styles/modal";
@import "./styles/label-value-pair";

body {
  margin: 0;
  font-family: $body-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $base-font-size;
  background-color: $white;
}

.flex-spacer {
  width: 1rem;
}
