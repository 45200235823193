@import "./variable";

.text-primary {
  font-size: 1rem;
  font-family: $body-font;
}

.link {
  @extend .text-primary;
  color: $product-accent;
  cursor: pointer;
}
