.m {
    &-1 {
        margin: 1rem;
    }

    &-top {
        &-1 {
            margin-top: 1rem;
        }
        &-2 {
            margin-top: 2rem;
        }
        &-3 {
            margin-top: 3rem;
        }
    }
}
