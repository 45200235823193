@import "./styles/variable";
@import "./styles/shadow";
@import "./styles/spinner";
@import "./styles/payment-method-forms";
@import "./styles/popup";

.payment-page-wrapper {
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(to bottom,#F3F6F9, #DDE1E5);
    width: 100%;
    height: 200px;
    z-index: -1;
  }

  &.loading::before {
    display: none;
  }

  .payment-summary {
    top: 0;
    width: 100%;
    max-width: $content-width;

    .heading {
      font-family: $heading-font;
      font-size: 2rem;
      line-height: 1.2;
      color: $black;
      margin: 12px 0;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      span {
        flex: 1;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @media only screen and (max-width: 768px) {
        font-size: 1.6rem;
      }
    }

    .payment-summary-wrapper {
      width: 100%;
      background-color: white;
      padding: 16px;
      border-radius: 4px;
      @extend .shadow-1;

      .payment-summary-row {
        display: flex;
        margin-bottom: 1rem;

        .payment-summary-cell {
          flex: 1;

          .link {
            margin: 8px 0;
            padding-top: 22px;
            font-size: 0.75rem;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .payment-page-content {
    max-width: $content-width;
    width: 100%;
    margin-top: 1rem;

    .payment-method-selection {
      display: flex;

      .payment-method-list-wrapper {
        flex: 1;

        .payment-method-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background-color: white;
          padding: 16px;
          margin: 16px 0;
          cursor: pointer;
          transition: box-shadow 0.1s ease-in;
          border: 1px solid transparent;
          @extend .shadow-1 !optional;

          &:hover {
            @extend .shadow-2 !optional;
          }

          &.selected {
            border: 1px solid $product-accent;
          }

          .payment-method-name {
            flex: 1;
            padding: 0 0.5rem;
          }

          .payment-method-icon {
            img {
              width: $payment-icon-width;
            }
          }

          .grey-payment-method-icon {
            img {
              width: $payment-icon-width;
              filter: grayscale(100%);
            }
          }
        }

        .payment-method-list {
          .label {
            font-family: $body-font;
            font-size: 0.75rem;
            color: $light-gray;
            margin-bottom: 16px;
          }
        }

        div[disabled]
        {
          pointer-events: none;
          opacity: 0.7;
        }
      }

      .payment-invoice-wrapper {
        flex: 1;
        height: fit-content;
        padding: 1rem;
        border: 2px dashed $lighter-gray;
        border-radius: 4px;
        margin-top: 28px;

        button {
          margin-top: 1rem;
        }

        .warning-message {
          font-size: 0.66rem;
          color: $gray;
          margin-top: 1rem;
        }
      }
    }
  }
}

.error-page-wrapper {
  padding: 24px;

  .error-page {
    width: 970px;
    height: 360px;
    max-width: 100%;
    padding: 20px;
    background: #ffffff;
    border-radius: 4px;
    border: 2px dashed #dddddd;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 100px auto;
    flex-direction: column;

    .title {
      font-size: 3rem;
      line-height: 4rem;
      font-weight: bold;
    }

    .message {
      font-size: 1.2rem;
      line-height: 2rem;
      margin: 1rem 0;
    }

    .action {
      width: auto;
      margin: 0.6rem 0 0 0;
    }
  }
}

.page-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;

  img {
    margin: 0.5rem 0;
  }
}

.selected-payment-drawer {
  .drawer-heading {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $light-gray;
    padding: 1rem;

    .payment-method-name {
      flex: 1;
    }

    .payment-method-icon {
      img {
        width: 36px;
      }
    }
  }

  .drawer-body {
    padding: 1rem;
  }

  .drawer-footer {
    padding: 1rem;
  }
}

.payment-invoice {
  ul.invoice-item-list {
    li.invoice-item {
      .invoice-item-row {
        padding: 0.5rem 0;
        display: flex;

        .invoice-item-label {
          flex: 1;
        }

        &.total {
          font-size: 1.2rem;
        }
      }

      .invoice-item-breakdown {
        margin-left: 1rem;

        .invoice-sub-item {
          padding: 0.5rem 0;
          display: flex;

          .invoice-sub-item-label {
            flex: 1;
          }
        }
      }
    }

    hr {
      border: none;
      border-top: 1px solid $lighter-gray;
    }
  }
}

.direct-debit-terms-modal {
  .modal-header {
    font-size: 1.5rem;
    font-family: $heading-font;
  }

  .modal-body {
    line-height: 1.5rem;
    text-align: justify;

    p {
      margin-bottom: 1.5rem;
    }
  }
}