@import "./variable.scss";

.btn {

    display: block;
    box-shadow: none;
    padding: 4px 8px;
    outline: none;
    border: none;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.1s ease-in;
    font-size: 1.2rem;

    &-primary {
        @extend .btn;
        background-color: $product-accent;
        color: $white;

        &[disabled] {
            cursor: default;
            background-color: $light-gray;
        }

        &-border {
            @extend .btn;
            background-color: transparent;
            color: $gray;
            border: 1px solid $product-accent;

            &:hover {
                background-color: rgba($color: $product-accent, $alpha: 0.1);
            }
        }
    }
}