@import "../styles/variable.scss";

.drawer-wrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;

    .drawer-backdrop {
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.4);
    }

    .drawer-content {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: $white;
        // padding: 16px;
    }
}