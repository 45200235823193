@import "./variable";

.label-value-pair {

    .label {
        color: $light-gray;
        font-size: 0.8rem;
        margin-bottom: 0.2rem;
    }

    .value {
        color: $gray;
        font-size: 1rem;
    }

    &.right {
        text-align: right;
    }
}