@import "./variable";

.form-group {
  width: 100%;
  font-size: 1rem;
  position: relative;

  &--small {
    width: 33%;
  }

  &--medium {
    width: 66%;
  }

  &.error {
    input[type="text"] {
      border-color: $error-dark;
    }
  }

  label {
    display: block;
    color: $light-gray;
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
  }

  input {
    font-size: 1rem;
  }

  small.error-message {
    font-size: 0.8rem;
    line-height: 0.8rem;
    color: $error-dark;
    font-weight: bold;
    position: absolute;
    bottom: -16px;
  }

  input[type="text"] {
    display: block;
    width: 100%;
    border: 1px solid $lighter-gray;
    border-radius: 4px;
    margin: 8px 0 24px 0;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    transition: 0.3s;
  }

  input[type="text"]:focus {
    border-color: $product-accent;
    box-shadow: rgba($product-accent, 0.2) 0px 0px 12px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
  }

  .checkbox {
    position: relative;
    display: flex;
    align-items: center;

    input[type="checkbox"] {
      width: 24px;
      height: 24px;
      margin: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      cursor: pointer;

      &:checked + span.check-icon:after {
        opacity: 1;
      }
    }

    span.check-icon {
      background: none repeat scroll 0 0 #eeeeee;
      border: 1px solid $lighter-gray;
      cursor: pointer;
      left: 0;
      position: absolute;
      top: 0;
      height: 22px;
      width: 22px;
      pointer-events: none;
      transition: 0.3s;
      border-radius: 4px;

      &:after {
        border-color: $product-accent;
        border-style: none none solid solid;
        content: "";
        height: 5px;
        left: 5px;
        opacity: 0;
        position: absolute;
        top: 5px;
        -ms-transform: rotate(-45deg); /* IE 9 */
        -webkit-transform: rotate(-45deg); /* Safari and Chrome */
        transform: rotate(-45deg);
        width: 10px;
      }
    }

    .space {
      width: 32px;
    }

    label {
      display: block;
      color: $black;
      font-size: 1rem;
      line-height: 1.5rem;
      margin: 0;
      flex: 1;

      &.error {
        color: $error-dark;
      }
    }
  }
}
