@import "./shadow";
@import "./variable";

.modal-wrapper {
  position: fixed;
  z-index: 1000;    
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.4);
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content {
    width: calc(100% - 48px);
    max-width: 480px;
    top: 100px;
    background: white;
    border-radius: 4px;
    @extend .shadow-3;

    .modal-header {
      padding: 24px;
      border-bottom: 1px solid $lighter-gray;
    }

    .modal-body {
        padding: 24px;
        max-height: 67vh;
        overflow-y: auto;
    }

    .modal-footer {
        padding: 24px;
        border-top: 1px solid $lighter-gray;
    }
  }
}
