@import './variable';

.status-alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  line-height: 1.5rem;
  font-size: 1rem;

  &.status {
    &-success {
      color: $success-dark;
      border-color: $success-medium;
      background-color: $success-light;
    }

    &-error {
      color: $error-dark;
      border-color: $error-medium;
      background-color: $error-light;
    }
  }
}

.toast-message-container {

  position: relative;
  border: 2px solid;
  border-radius: 4px;
  border-left: 8px solid;
  padding: 20px;
  background: white;

  &--error {
    border-color: red;

  }

  .toast-message-close {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    font-weight: 600;
  }

  .toast-message {
    text-align: center;

  }
}