@import "../styles/variable.scss";

.nv-popup {
  .nv-popup-overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100vw; /* Full width (cover the whole page) */
    height: 100vh; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);/* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */

  }

  .nv-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    max-width: 800px;

    margin: 20% auto;

    .nv-popup-content{

    }
  }


}

@media only screen and (max-width: 768px) {
  .nv-popup{
    .nv-popup-container{
      top: unset;
      margin: auto;
    }
  }
}